import * as React from 'react';
import Link from '../link';

const TreeNode = ({ className = '', url, title, items }) => {
  const hasChildren = items.length !== 0;

  let location;

  if (typeof document !== 'undefined') {
    location = document.location;
  }
  const active = location && (location.pathname === url);

  const calculatedClassName = `${className} item ${active ? 'active' : ''}`;

  return (
    <li className={calculatedClassName}>
      {title && (
        <Link to={url}>
          {title}
        </Link>
      )}

      {hasChildren && (
        <ul>
          {items.map((item, index) => (
            <TreeNode
              key={item.url + index.toString()}
              {...item}
            />
          ))}
        </ul>
      )}
    </li>
  );
};

export default TreeNode;
